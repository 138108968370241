// Stylesheets
import "../scss/theme.scss";

import "./fa-build.js";

import "@fortawesome/fontawesome";

document.getElementById('search').addEventListener("click", switchDisplay);
document.getElementById('hamburger').addEventListener("click", responsiveNavMenu);

function switchDisplay() {
    let el = document.querySelector('#search-form-top');
    if(el.style.display === "block") {
        el.style.display = "none";
    } else {
        el.style.display = "block";
        document.getElementById("searchInput").focus();
    }
}

function responsiveNavMenu() {
    let el = document.querySelector('#responsive-menu');
    if(el.style.display === "flex") {
        el.style.display = "none";
    } else {
        el.style.display = "flex";
    }
}